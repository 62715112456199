import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";
import OSSection from "../components/other-services-section";
import ReviewSection from "../components/review-section";
import SeoHero from "../components/seo-hero";
import SeoMapSection from "../components/seo-map-section";
import SeoPageLogos from "../components/seo-page-logos";
import SeoResultsInYourArea from "../components/seo-results-in-your-area";
import SeoVideoSection from "../components/seo-video-section";
import SeoWhatWeSolve from "../components/seo-what-we-solve";
import SeoWwaSection from "../components/seo-wwa-section";

const WebDesignLondonPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			videos: allWpVideo(
				filter: { videoFields: { seoPage: { in: "Web Design London" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					videoFields {
						url
						seoPage
					}
					title
				}
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			ecfImg: wpMediaItem(title: { eq: "Ellaroberta-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ecfsmallImg: wpMediaItem(title: { eq: "ecf-two-1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			arrowImg: wpMediaItem(title: { eq: "arrow" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			eccImg: wpMediaItem(title: { eq: "Eastbourne-commerce-white" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			laptopImg: wpMediaItem(title: { eq: "webdesign-laptop" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			foehImg: wpMediaItem(title: { eq: "capitaltocoast-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			wpSeoPage(title: { eq: "Web Design London" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}
			allWpPost(
				filter: { blogFields: { seoPage: { in: "Web Design London" } } }
				sort: { fields: dateGmt, order: DESC }
			) {
				nodes {
					slug
					excerpt
					title
					dateGmt
					blogFields {
						category
						keywords
						seoTitle
						featuredImage {
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
				}
			}
			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Web Design Agency London",
				item: {
					url: `${siteUrl}/web-design-london`,
				},
			},
		],
	};
	function convertToSlug(Text) {
		return Text.toLowerCase()
			.replace(/ /g, "-")
			.replace(/[^\w-]+/g, "");
	}

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/web-design-london`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<SeoHero
						page="Web Design Agency London"
						form
						title={`Web Design \n<span class="text-secondary">London</span>`}
						description={
							<span>
								Helping London businesses get more leads and customers from
								<br className="d-none d-lg-block" />
								their website with bespoke web design.
							</span>
						}
					/>
				</section>

				<SeoWwaSection
					spanLg={null}
					heading="Looking to build a new website?"
					text={
						<div>
							<p>
								RJM Digital is a leading web design agency in London that helps
								organisations and businesses build brand-new bespoke websites.
								We don't just build websites that look pretty, we build them to
								drive traffic, convert them into leads and generate more revenue
								for your business.
							</p>
							<p>
								We ensure all our websites have a bespoke design that represents
								your brand, makes you stand out against your competitors and
								delivers a solid Return-on-Investment.
							</p>
						</div>
					}
					buttonText="Book a discovery call"
					buttonUrl="#form"
					imgTop={data.ecfsmallImg.gatsbyImage}
					imgTopAlt={data.ecfsmallImg?.altText}
					imgBot={data.ecfImg.gatsbyImage}
					imgBotAlt={data.ecfImg?.altText}
				/>
				<section className="py-5 py-lg-7">
					<SeoPageLogos
						heading={
							<span>
								We are proud to be trusted by many local London
								<br className=" d-none d-lg-block" /> businesses and
								organisations
							</span>
						}
						page="Web Design London"
					/>
				</section>
				<SeoMapSection mapUrl="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9928.942998834771!2d-0.0886605!3d51.5272357!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xd6972f4fcb15756d!2sRJM%20Digital!5e0!3m2!1sen!2sae!4v1673980072743!5m2!1sen!2sae" />
				<div className="bg-light-grey">
					<SeoResultsInYourArea
						heading="Rank at the top of Google in London"
						text={
							<div className="text-white">
								<p>
									You have a brand new website that you are proud of but you're
									not getting any enquiries, contact requests or leads from it?
									This is where Search engine optimisation can transform your
									website from an expense to a revenue-generating machine.
								</p>
								<p>
									{" "}
									All of our websites are primed to get found on all major
									search engines. SEO is a continually evolving process that
									requires constant monitoring and monitoring and we can provide
									all London businesses with the expertise to outrank your
									competitors and get their leads!
								</p>
							</div>
						}
						buttonText="Find out more about SEO in London"
						buttonUrl="/contact-us"
						imgTop={data.laptopImg.gatsbyImage}
						imgTopAlt={data.laptopImg?.altText}
						// imgBot={data.eccImg.gatsbyImage}
						// imgBotAlt={data.eccImg?.altText}
						imgBot2={data.arrowImg.gatsbyImage}
						imgBot2Alt={data.arrowImg?.altText}
						bottomContent={
							<Row className="g-5 bx-lg-7">
								<Col className="text-center" lg={4}>
									<h3 className="display-4 ssp-bold text-primary">50+</h3>
									<p className="ssp-semibold" style={{ fontSize: "100%" }}>
										Happy
										<br /> customers
									</p>
								</Col>
								<Col className="text-center" lg={4}>
									<h3 className="display-4 ssp-bold text-primary">6</h3>
									<p className="ssp-semibold" style={{ fontSize: "100%" }}>
										Average months for
										<br /> Return on Investment
									</p>
								</Col>
								<Col className="text-center" lg={4}>
									<h3 className="display-4 ssp-bold text-primary">5+</h3>
									<p className="ssp-semibold" style={{ fontSize: "100%" }}>
										Years working with London
										<br /> businesses
									</p>
								</Col>
							</Row>
						}
					/>
					<SeoWhatWeSolve
						heading="How we build our websites"
						image={data.foehImg.gatsbyImage}
						imageAlt={data.foehImg?.altText}
						imgHeight="35rem"
						text={
							<div>
								<h3 className="text-primary fs-2 ">
									Wordpress and GatsbyJS{" "}
									<span className="text-secondary">website</span>
								</h3>
								<p>
									We build our websites using a popular open-source content
									management system (WordPress websites) where you can add
									content, media files and data to your website. We add an
									additional layer called GatsbyJS to give your website
									ultra-fast loading speeds, resulting in a great user
									experience, responsive design and a higher chance of website
									conversions.
								</p>
								<h3 className="mt-5 text-primary fs-2">
									Bespoke website design{" "}
									<span className="text-secondary">London</span>
								</h3>
								<p>
									We will design your bespoke website using our website mock-up
									tools where you will get unlimited revisions and changes to
									ensure that you get a website that you can be proud of. We
									will never purchase off-the-shelf templates to design your
									London website and you can be assured that our expert web
									designers will walk you through every step of the way.
								</p>
							</div>
						}
					/>
				</div>
				{/* <div id="pricing">
					<PricingSection />
				</div> */}
				<SeoVideoSection single videos={data.videos} />

				<section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection
						heading="Web Design London Reviews"
						page="Our Web Services"
					/>
				</section>
				<section className="py-5 py-lg-7 ">
					<Container>
						<Row>
							<Col>
								<h2 className="mb-5 text-primary fs-1">
									Recent London Web Design blog articles
								</h2>
							</Col>
						</Row>
						<Row className="g-5 gx-lg-6 gx-xl-7">
							{data.allWpPost.nodes.map((blog, index) => (
								<Col lg={6}>
									<div
										style={{
											overflow: "hidden",
											borderRadius: "20px",
											boxShadow: "0px 3px 60px #00000029",
										}}
									>
										<GatsbyImage
											image={blog.blogFields.featuredImage.gatsbyImage}
											alt={blog.blogFields.featuredImage.altText}
											className="w-100 seo-blog-image"
										/>
										<div className="p-4 bg-white">
											<h2 className="mb-4 fs-4 pe-lg-10 text-primary ssp-bold">
												{blog.title}
											</h2>

											<Link
												to={`/blog/${convertToSlug(blog.blogFields.category)}/${
													blog.slug
												}`}
												className="med-grey-link ssp-bold"
											>
												READ BLOG
											</Link>
										</div>
									</div>
								</Col>
							))}
						</Row>
					</Container>
				</section>
				<section className="pt-5 pt-lg-7 pb-xl-7 bg-light-grey">
					<Container>
						<Row>
							<Col className="text-center">
								{" "}
								<h2 className="mb-3 display-5 text-primary">
									Book a discovery meeting
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				<OSSection
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1?.altText}
				/>
			</Layout>
		</>
	);
};

export default WebDesignLondonPage;
